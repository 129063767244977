import * as React from 'react';
import { Routes, Route, Link } from "react-router-dom";

import Login from './components/Login'
import Dashboard from './components/Dashboard'
import RefundStamina from './components/RefundStamina'
import SendMail from './components/SendMail'
import ClientVersion from './components/ClientVersion'
import Logout from './components/Logout'

// import Button from '@mui/material/Button';
import {
  useNavigate,
} from "react-router-dom";
import AuthProvider from './AuthProvider'
import UserReport from './components/UserReport';
import Tournament from './components/Tournament';
import TournamentScore from './components/TournamentScore';

function App() {

    // check Auth
    let navigate = useNavigate();
    React.useEffect(() => {
      AuthProvider.checkAuth().then(null,()=>{
          navigate('/login', { replace: true });
      });
    },[]);

  return (
    <div className="App">
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="login" element={<Login />} />

      <Route path="refund-stamina" element={<RefundStamina />} />
      <Route path="send-email" element={<SendMail />} />
      <Route path="client-version" element={<ClientVersion />} />
      <Route path="user-report" element={<UserReport />} />
      <Route path="tournament" element={<Tournament />} />
      <Route path="tournament-score" element={<TournamentScore />} />
      
      <Route path="logout" element={<Logout />} />
      
      
    </Routes>
  </div>
  );
}

export default App;
