import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './MenuItems';
import Copyright from './Copyright'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import AppBar from './AppBar'
import Drawer from './Drawer'
import Notification from './Notification'
import AuthProvider from '../AuthProvider'

const mdTheme = createTheme();



function ClientVersionContent() {

  // Drawer
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

 // Noti 
  const [notiOpen, setNotiOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const [platformField, setPlatformField] = React.useState("web");
  const handleChangePlatform = (event) => {
    setPlatformField(event.target.value);
  };

  const [envField, setEnvField] = React.useState("dev");
  const [versionField, setVersionField] = React.useState("");
  const [dataField, setDataField] = React.useState("");

  const notiClose = () => {
    setNotiOpen(false)
  }

  const loadConfig = async (event) => {

    const url = process.env.REACT_APP_LOAD_GAME_CONFIG_ENDPOINT + `?version=${versionField}&env=${envField}&platform=${platformField}`;
    // console.log(url);
    fetch(url)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(({ data, error_code, success, server_time }) => {
        

        if(error_code !== 0)
        {
          setDataField("");
          setMessage("Error!");
          setNotiOpen(true)

        }
        else
        {
          setDataField(JSON.stringify(data, null, "\t"));
          // console.log({ data, error_code, success, server_time });
          setMessage("Load Success");
          setNotiOpen(true)
        }


    }).catch(error => {
          setMessage(error);
          setNotiOpen(true)
    });;
  }



  const handleSubmit = (event) => {
    event.preventDefault();
    
    if(envField === "release")
    {
      const answer = window.confirm("PRODUCTION Env! Are you sure you want to create?");
      if (!answer) {
          return;
      }
    }

    const request = new Request(process.env.REACT_APP_CREATE_GAME_CONFIG_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({ env: envField, platform: platformField,version: parseInt(versionField), data: JSON.parse(dataField)}),
      headers: new Headers({ 'Content-Type': 'application/json',
      auth: AuthProvider.getToken() })
  });

  return fetch(request)
      .then((response) => {
          if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
          }
          return response.json();
      })
      .then(({ data, error_code }) => {

        // console.log(data);
        if(error_code !== 0)
        {
          setMessage("Error!");
          setNotiOpen(true)

        }
        else
        {
          setMessage("Success");
          setNotiOpen(true)
          
        }

      }).catch(error => {
          setMessage(error);
          setNotiOpen(true)
      });;


  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Client Version
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  {/* ***************************** */}
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="version"
                    label="version"
                    name="version"
                    autoFocus
                    value={versionField}
                    onChange={(event) => {
                      setVersionField(event.target.value);
                    }}
                  />


            <InputLabel id="env_label">Env:</InputLabel>
              <Select
                labelId="env_label"
                id="env"
                label="Env"
                value={envField}
                onChange={(event) => {
                  setEnvField(event.target.value);
                }}
              >
                <MenuItem value={"dev"}>dev</MenuItem>
                <MenuItem value={"release"}>release</MenuItem>
              </Select>


  <InputLabel id="flatform_label">Platform:</InputLabel>
  <Select
    labelId="flatform_label"
    id="platform"
    label="Platform"
    value={platformField}
    onChange={handleChangePlatform}
  >
    <MenuItem value={"web"}>Web</MenuItem>
    <MenuItem value={"ios"}>iOS</MenuItem>
    <MenuItem value={"android"}>Android</MenuItem>
  </Select>

  <Stack direction="row" sx={{ pt: 1, pb:1 }}>
  <Button onClick={loadConfig}  variant="outlined">Load Config</Button>
  </Stack>
  

<TextField
  style={{textAlign: 'left'}}
  multiline
  rows={10}
  fullWidth
  value={dataField}
      onChange={(event) => {
        setDataField(event.target.value);
      }}
/>


                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Create
                  </Button>
                </Box>

          {/* ***************************** */}
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Notification message={message} isOpen={notiOpen} onClose={notiClose}/>
    </ThemeProvider>
  );
}

export default function ClientVersion() {
  return <ClientVersionContent />;
}