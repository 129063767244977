import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './MenuItems';
import Copyright from './Copyright'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import AppBar from './AppBar'
import Drawer from './Drawer'
import Notification from './Notification'
import AuthProvider from '../AuthProvider'

const mdTheme = createTheme();


function SendMailContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //
  const [receiver, setReceiver] = React.useState("");
  const handleChangeReceiver = (event) => {
    setReceiver(event.target.value);
  };

  // Select ItemId
  const [itemId, setItemId] = React.useState(10099);

  const handleChangeItemId = (event) => {
    setItemId(event.target.value);
  };

  // Select type
  const [typeId, setTypeId] = React.useState(0);

  const handleChangeTypeId = (event) => {
    if(event.target.value === 1)
    {
      setReceiver("");
    }

    setTypeId(event.target.value);
  };

  
  const [notiOpen, setNotiOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const notiClose = () => {
    setNotiOpen(false)
  }



  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console


    const receiver = data.get('receiver');
    const title = data.get('title');
    const message = data.get('message');
    const count = data.get('count');

    // console.log({ 
    //   type: 0,
    //   sender: "System",
    //   receiver, 
    //   title, 
    //   message, 
    //   rewards: {
    //     items: [
    //       {
    //         item_id: itemId,
    //         count: parseInt(count)
    //       }
    //     ]
    //   } });

    let request = new Request(process.env.REACT_APP_SEND_MAIL_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ 
          type: typeId,
          sender: "System",
          receiver, 
          title, 
          message, 
          rewards: {
            items: [
              {
                item_id: itemId,
                count: parseInt(count)
              }
            ]
          } }),
        headers: new Headers({ 'Content-Type': 'application/json',
        auth: AuthProvider.getToken() })
    });

    if(parseInt(count) === 0)
    {
      request = new Request(process.env.REACT_APP_SEND_MAIL_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ 
          type: typeId,
          sender: "System",
          receiver, 
          title, 
          message
        }),
        headers: new Headers({ 'Content-Type': 'application/json',
        auth: AuthProvider.getToken() })
    });
    }

    return fetch(request)
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(({ data, code }) => {
          // console.log(data);
          if(code !== 0)
          {
            setMessage("Error!");
            setNotiOpen(true)

          }
          else
          {
            setMessage("Success");
            setNotiOpen(true)
            
          }

        }).catch(error => {
            setMessage(error);
            setNotiOpen(true)
        });;
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              SendMail
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  {/* ***************************** */}
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

  <InputLabel id="type_label">Send To:</InputLabel>
  <Select
    labelId="type_id_label"
    id="type_id"
    label="Type"
    value={typeId}
    onChange={handleChangeTypeId}
  >
    <MenuItem value={0}>One User</MenuItem>
    <MenuItem value={1}>All Users</MenuItem>
  </Select>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="receiver"
                    label="receiver"
                    name="receiver"
                    autoFocus
                    disabled= {typeId === 1 ? true:false}
                    onChange={handleChangeReceiver}
                    value={receiver}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="title"
                    label="title"
                    id="title"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="message"
                    label="message"
                    id="message"
                  />


  <InputLabel id="item_id_label">Item Id</InputLabel>
  <Select
    labelId="item_id_label"
    id="item_id"
    label="Item Id"
    value={itemId}
    onChange={handleChangeItemId}
  >
    <MenuItem value={10099}>Stamina (10099)</MenuItem>
  </Select>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="count"
                    label="count"
                    id="count"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send
                  </Button>
                </Box>

          {/* ***************************** */}
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Notification message={message} isOpen={notiOpen} onClose={notiClose}/>
    </ThemeProvider>
  );
}

export default function SendMail() {
  return <SendMailContent />;
}