import * as React from 'react';
import {useNavigate} from "react-router-dom";
import AuthProvider from '../AuthProvider'

function Logout() {

    // check Auth
    let navigate = useNavigate();
    React.useEffect(() => {
      AuthProvider.logout().then(()=>
          {
            navigate('/login', { replace: true });
          }
      )
    },[]);

  return (
    <div className="App">
  </div>
  );
}

export default Logout;
