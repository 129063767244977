import Snackbar from '@mui/material/Snackbar';


export default function Notification({ message, isOpen, onClose }) {

    return (
        <Snackbar
            key={Math.random()}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            open={isOpen}
            autoHideDuration={5000}
            message={message}
            onClose={onClose}
        />
    )
};