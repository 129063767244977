import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import EmailIcon from '@mui/icons-material/Email';
import NumbersIcon from '@mui/icons-material/Numbers';
import BugReportIcon from '@mui/icons-material/BugReport';
import GroupIcon from '@mui/icons-material/Group';
import TourIcon from '@mui/icons-material/Tour';

export const mainListItems = (
  <React.Fragment>

    <ListItemButton component="a" href="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>

    <ListItemButton component="a" href="refund-stamina" >
      <ListItemIcon>
        <SettingsBackupRestoreIcon />
      </ListItemIcon>
      <ListItemText  primary="Refund Stamina"/>
    </ListItemButton>


    <ListItemButton  component="a" href="send-email" >
      <ListItemIcon>
        <EmailIcon/>
      </ListItemIcon>
      <ListItemText primary="Email" />
    </ListItemButton>

    <ListItemButton  component="a" href="client-version" >
      <ListItemIcon>
        <NumbersIcon/>
      </ListItemIcon>
      <ListItemText primary="Client Version" />
    </ListItemButton>

    <ListItemButton  component="a" href="user-report" >
      <ListItemIcon>
        <BugReportIcon/>
      </ListItemIcon>
      <ListItemText primary="User Report" />
    </ListItemButton>

    <ListItemButton  component="a" href="tournament" >
      <ListItemIcon>
        <TourIcon/>
      </ListItemIcon>
      <ListItemText primary="Tournament Match" />
    </ListItemButton>

    <ListItemButton  component="a" href="tournament-score" >
      <ListItemIcon>
        <TourIcon/>
      </ListItemIcon>
      <ListItemText primary="Tournament Score" />
    </ListItemButton>

    <Divider sx={{ my: 1 }} />
    <ListItemButton  component="a" href="logout" >
      <ListItemIcon>
        <LogoutIcon/>
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItemButton>

  </React.Fragment>

);

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//       Saved reports
//     </ListSubheader>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );