import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './MenuItems';
import Copyright from './Copyright'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import AppBar from './AppBar'
import Drawer from './Drawer'
import Notification from './Notification'
import AuthProvider from '../AuthProvider'

const mdTheme = createTheme();


function formatDate(date, format) {
  const map = {
      mm: (date.getMonth() + 1).toString(),
      dd: date.getDate().toString(),
      yyyy: date.getFullYear().toString()
  }

  console.log(map)

  if (map.mm.length < 2) 
      map.mm = '0' + map.mm;
  if (map.dd.length < 2) 
      map.dd = '0' + map.dd;

  return format.replace(/mm|dd|yyyy/gi, matched => map[matched])
}



function RefundStaminaContent() {

  // Drawer
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // date
  const [dateTime, setDateTime] = React.useState(new Date());

 // Noti 
  const [notiOpen, setNotiOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const notiClose = () => {
    setNotiOpen(false)
  }



  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console


    const address = data.get('address');
    const stamina = parseInt(data.get('stamina'));

    console.log(dateTime);

    const timeSend = formatDate(new Date(dateTime), 'yyyy-mm-dd');

    console.log({ address, stamina, time: timeSend});

    const request = new Request(process.env.REACT_APP_REFUND_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ address, stamina, time: timeSend}),
        headers: new Headers({ 'Content-Type': 'application/json',
        auth: AuthProvider.getToken() })
    });

    return fetch(request)
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then(({ data, error_code, success }) => {
          console.log(data);
          if(error_code !== 0 || success !== 1)
          {
            setMessage("Error!");
            setNotiOpen(true)

          }
          else
          {
            setMessage("Success");
            setNotiOpen(true)
            
          }

        }).catch(error => {
            setMessage(error);
            setNotiOpen(true)
        });;
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              RefundStamina
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  {/* ***************************** */}
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="address"
                    label="address"
                    name="address"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="stamina"
                    label="stamina"
                    id="stamina"
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Time"
                        value={dateTime}
                        onChange={(newValue) => {
                          setDateTime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                  </LocalizationProvider>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Refund
                  </Button>
                </Box>

          {/* ***************************** */}
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Notification message={message} isOpen={notiOpen} onClose={notiClose}/>
    </ThemeProvider>
  );
}

export default function RefundStamina() {
  return <RefundStaminaContent />;
}