import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './MenuItems';
import Copyright from './Copyright'

import AppBar from './AppBar'
import Drawer from './Drawer'
import Notification from './Notification'
import AuthProvider from '../AuthProvider'

const mdTheme = createTheme();


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

 
  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (
      event,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  
  function createData(id, player, type, desc, data, createdAt, updatedAt) {
    return { id, player, type, desc, data, createdAt, updatedAt };
  }

export function getList(page, size) {
    page+=1;
    const request = new Request(process.env.REACT_APP_USER_REPORT_ENDPOIN + `?page=${page}&size=${size}`, {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json',
        auth: AuthProvider.getToken() })
    });

    return fetch(request)
    .then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
    .then(({ data, error_code }) => {

      if(error_code !== 0)
      {
      }
      else
      {
          return data;
      }

    }).catch(error => {
    });;

}

function UserReportContent() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // **************************************************

  const [rows, setRows] = React.useState([]);

  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalReport, setTotalReport] = React.useState(0);
  
  React.useEffect(() => {
    let mounted = true;

    getList(page,rowsPerPage)
      .then(items => {
        if(mounted) {
            let report_row = [];
            for (let i = 0; i < items.reports.length; i++) {
              let rpt = items.reports[i];
              report_row.push({id: rpt.id, player: rpt.player, type: rpt.type, desc: rpt.desc, data: rpt.data, createdAt: rpt.createdAt, updatedAt: rpt.updatedAt});
            }
            setTotalReport(items.total)
            setRows(report_row)
        }
      })
    return () => mounted = false;
  }, [rowsPerPage, page])


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              User Report
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="false" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    {/* ******************************************** */}

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>ID</StyledTableCell>
                                    <StyledTableCell align="left">Player</StyledTableCell>
                                    <StyledTableCell align="left">Type</StyledTableCell>
                                    <StyledTableCell align="left">Desc</StyledTableCell>
                                    <StyledTableCell align="left">Data</StyledTableCell>
                                    <StyledTableCell align="left">CreatedAt</StyledTableCell>
                                </TableRow>
                                </TableHead>
                            <TableBody>
                            {
                            rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell style={{ width: 20, verticalAlign: "top", fontSize: 13 }} align="left" component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell style={{ width: 380 , verticalAlign: "top", fontSize: 13  }} align="left">
                                        {row.player}
                                    </TableCell>
                                    <TableCell style={{ width: 50 , verticalAlign: "top", fontSize: 13 }}  align="left">
                                        {row.type}
                                    </TableCell>
                                    <TableCell style={{ width: 200, verticalAlign: "top", fontSize: 13, wordWrap: "break-word", whiteSpace: "normal" }}  align="left">
                                        {row.desc}
                                    </TableCell>
                                    <TableCell style={{verticalAlign: "top", fontSize: 13 }} align="left">
                                        {JSON.stringify(row.data, null, "\t")}
                                    </TableCell>
                                    <TableCell style={{ width: 50, verticalAlign: "top", fontSize: 13 }}  align="left">
                                        {row.createdAt}
                                    </TableCell>
                                    
                                </TableRow>
                            ))}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                            </TableBody>
                            <TableFooter>
                            <TableRow>
                                <TablePagination
                                rowsPerPageOptions={[1, 2, 5, 10, 25]}
                                colSpan={3}
                                count={totalReport}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                    'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                            </TableFooter>
                        </Table>
                        </TableContainer>

                    {/* ******************************************** */}
                  
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
        
    </ThemeProvider>
  );
}

export default function UserReport() {
    return <UserReportContent />;
  }