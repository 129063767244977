const LOGIN_ENDPOINT = 'https://test.thewastedlands.io/api/v1/login/login_user';
 
 const AuthProvider = {
    // called when the user attempts to log in
    login: async ({ username, password }) => {
        const request = new Request(LOGIN_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });


        const response = await fetch(request);
        const data = await response.json();
        console.log(data);


        // return fetch(request)
        // .then((response) => {
        //     if (response.status < 200 || response.status >= 300) {
        //         localStorage.removeItem('token');
        //         throw new Error(response.statusText);
        //     }
        //     return response.json();
        // })
        // .then(({ data, error_code }) => {

        //     if(error_code !== 0)
        //     {
        //         localStorage.removeItem('token');
        //         throw new Error("Invalid Credential.");
        //     }

        //     console.log(data);
        //     localStorage.setItem('token', data.token);
        // });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
    getToken: () => {
        return localStorage.getItem('token');
    },
};

export default AuthProvider;