import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './MenuItems';
import Copyright from './Copyright'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import AppBar from './AppBar'
import Drawer from './Drawer'
import Notification from './Notification'
import AuthProvider from '../AuthProvider'

const mdTheme = createTheme();

export function getGameConfig() {

  const request = new Request(process.env.REACT_APP_TOURNAMENT_CONFIG, {
      method: 'GET',
      headers: new Headers({ 'accept': 'application/json',
        auth: AuthProvider.getToken() })
  });


  return fetch(request)
    .then(data => data.json())

  // fake
  // return  Promise.resolve(JSON.parse('{"success":1,"error_code":0,"server_time":"2022-04-08T03:57:09.705Z","data":{"configs":[{"name":"BattleReduceStamina","value":"2"},{"name":"StaminaIncreaseByTime","value":"1"},{"name":"StaminaIncreaseTime","value":"1"},{"name":"HeroPartMd5","value":"0899a3b88e949dbb3c1a0847bdd264b9"},{"name":"ActiveSkillMd5","value":"f64279112d32bc123f3eb3c364c21a6c"},{"name":"HeroPartUrl","value":"/data/hero_part.dat"},{"name":"ActiveSkillUrl","value":"/data/active_skill.dat"}],"tournament":{"id":7,"name_code":"tour4","name":"Tour 4","type":"paid","ticket_time":"2022-04-08T01:20:00.000Z","start_time":"2022-04-08T02:00:00.000Z","end_time":"2022-04-08T05:00:00.000Z","match":10,"ticket_price":20,"ticket_sold":6,"total_value":120,"heroes":[6,221,222,224,225,226],"createdAt":"2022-03-23T09:11:55.000Z","updatedAt":"2022-04-08T03:26:38.000Z"},"tournaments":[{"id":8,"name_code":"tour8","name":"Tour 8","type":"paid","ticket_time":"2022-04-08T01:20:00.000Z","start_time":"2022-04-08T02:00:00.000Z","end_time":"2022-04-08T05:00:00.000Z","match":10,"ticket_price":20,"ticket_sold":6,"total_value":120,"heroes":[6,221,222,224,225,226],"createdAt":"2022-03-23T09:11:55.000Z","updatedAt":"2022-04-08T03:26:38.000Z"},{"id":7,"name_code":"tour4","name":"Tour 4","type":"paid","ticket_time":"2022-04-08T01:20:00.000Z","start_time":"2022-04-08T02:00:00.000Z","end_time":"2022-04-08T05:00:00.000Z","match":10,"ticket_price":20,"ticket_sold":6,"total_value":120,"heroes":[6,221,222,224,225,226],"createdAt":"2022-03-23T09:11:55.000Z","updatedAt":"2022-04-08T03:26:38.000Z"}]}}'));

}


function TournamentScoreContent() {

  // Drawer
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

 // Noti 
  const [notiOpen, setNotiOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  // Get Config
  const [gameConfig, setGameConfig] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    getGameConfig()
      .then(result => {
        console.log(result)
        if(mounted) {
          
          if(result.success === 1)
          {
            setGameConfig(result.data.tournaments)
            console.log(result.data.tournaments)
          }

          result.data.tournaments.map((row) => {
            console.log(row.name_code)
          })
        }
      })
    return () => mounted = false;
  }, [])



  // const [platformField, setPlatformField] = React.useState("web");
  // const handleChangePlatform = (event) => {
  //   setPlatformField(event.target.value);
  // };

  
  const [playerField, setPlayerField] = React.useState("");
  const [tournamentField, setTournamentField] = React.useState("");
  // const [dataField, setDataField] = React.useState("");
  const [tournamentScoreField, setTournamentScoreField] = React.useState(0);



  const notiClose = () => {
    setNotiOpen(false)
  }

  const loadPlayerTournament = async (event) => {

    const url = process.env.REACT_APP_GET_PLAYER_TOURNAMENT + `?player=${playerField}&tournament=${tournamentField}`;

    const request = new Request(url, {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json',
        auth: AuthProvider.getToken() })
  });


    // console.log(url);
    fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(({ data, error_code, success }) => {
        
        console.log(data)
        if(success !== 1)
        {
          setMessage("Error!");
          setNotiOpen(true)

        }
        else
        {
            console.log(data);
          if(data)
          {
            setTournamentScoreField(data.tournamentScore?data.tournamentScore.score:0)
            setMessage("Load Success");
            setNotiOpen(true)
          }
          else {
            setMessage("Player Tournament is Null");
            setNotiOpen(true)
          }

          // setDataField(JSON.stringify(data, null, "\t"));

        }


    }).catch(error => {
          setMessage(error);
          setNotiOpen(true)
    });;
  }


  const handleChangeTournament = (event) => {

    console.log(event.target.value)
    setTournamentField(event.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    const request = new Request(process.env.REACT_APP_UPDATE_PLAYER_TOURNAMENT_SCORE, {
      method: 'POST',
      body: JSON.stringify({ player: playerField, tournament: tournamentField,score: parseInt(tournamentScoreField)}),
      headers: new Headers({ 'Content-Type': 'application/json',
      auth: AuthProvider.getToken() })
  });

  return fetch(request)
      .then((response) => {
          if (response.status < 200 || response.status >= 300) {
              throw new Error(response.statusText);
          }
          return response.json();
      })
      .then(({ data, error_code, success }) => {

        console.log({ data, error_code, success });
        if(success !== 1)
        {
          setMessage("Error! " + error_code);
          setNotiOpen(true)

        }
        else
        {
          setMessage("Success");
          setNotiOpen(true)
          
        }

      }).catch(error => {
          setMessage(error);
          setNotiOpen(true)
      });;


  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Tournament
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {/* {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
{/* ***************************** */}
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="player"
                    label="Player (Address)"
                    name="player"
                    autoFocus
                    value={playerField}
                    onChange={(event) => {
                      setPlayerField(event.target.value);
                    }}
                  />


                <InputLabel id="tournament_label">Tournament :</InputLabel>
                <Select
                    labelId="tournament_label"
                    id="tournament"
                    label="Tournament"
                    value={tournamentField}
                    onChange={handleChangeTournament}
                >
                  {
                    gameConfig.map( (row) => {
                      return <MenuItem key={row.id} value={row.name_code}>{row.name}</MenuItem>
                    })
                  }
                </Select>


    <Stack direction="row" sx={{ pt: 1, pb:1 }}>
  <Button onClick={loadPlayerTournament}  variant="outlined">Load Data</Button>
  </Stack>

  {/* <TextField
  style={{textAlign: 'left'}}
  multiline
  rows={14}
  fullWidth
  value={dataField}
      onChange={(event) => {
        setDataField(event.target.value);
      }}
/> */}

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="match_count"
                    label="Score"
                    name="match_count"
                    autoFocus
                    value={tournamentScoreField}
                    onChange={(event) => {
                      setTournamentScoreField(event.target.value);
                    }}
                  />


                 <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Update
                  </Button>
                </Box>

          {/* ***************************** */}
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <Notification message={message} isOpen={notiOpen} onClose={notiClose}/>
    </ThemeProvider>
  );
}

export default function TournamentScore() {
  return <TournamentScoreContent />;
}